export const data = [
  {
    img: require("../../assets/images/phone-ico.webp"),
    title: "Reunión Inicial",
    description:
      "Programaremos una reunión inicial para comprender tus necesidades y objetivos comerciales.",
  },
  {
    img: require("../../assets/images/design-ico.webp"),
    title: "Desarrollo Web Personalizado",
    description:
      "Creamos un sitio web personalizado que refleje la identidad de tu negocio y satisfaga las necesidades de tus clientes.",
  },
  {
    img: require("../../assets/images/goal-ico.webp"),
    title: "Entrega y Soporte",
    description:
      "Entregamos el proyecto finalizado y proporcionamos soporte continuo para garantizar el éxito a largo plazo de tu presencia digital.",
  },
];