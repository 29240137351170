export const data = [
    {
        img: require("../../assets/images/chart-ico.webp"),
        title: "Análisis de Mercado",
        description: "Analizamos tu mercado objetivo, identificamos oportunidades y te ayudamos a posicionarte de manera efectiva."
    },
    {
        img: require("../../assets/images/design-ico.webp"),
        title: "Diseño Web Personalizado",
        description: "Creamos sitios web únicos y atractivos que reflejen la identidad de tu marca y cautiven a tus clientes potenciales."
    },
    {
        img: require("../../assets/images/optimization-ico.webp"),
        title: "Optimización de Velocidad",
        description: "Garantizamos que tu sitio web cargue rápidamente en cualquier dispositivo, lo que mejora la experiencia del usuario y aumenta la retención."
    },
    {
        img: require("../../assets/images/responsive-ico.webp"),
        title: "Diseño Responsivo",
        description: "Creamos sitios web que se adaptan perfectamente a cualquier dispositivo, lo que garantiza una experiencia de usuario consistente y agradable."
    },
    {
        img: require("../../assets/images/seo-ico.webp"),
        title: "Optimización para Motores de Búsqueda (SEO)",
        description: "Implementamos las mejores prácticas de SEO para mejorar la visibilidad de tu sitio web en los resultados de búsqueda y aumentar el tráfico orgánico."
    },
    {
        img: require("../../assets/images/security-ico.webp"),
        title: "Seguridad y Protección",
        description: "Protegemos tu sitio web contra amenazas cibernéticas y garantizamos la seguridad de los datos de tus clientes con medidas de seguridad avanzadas."
    },
]