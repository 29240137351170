import React from "react";
import "./weoffer.scss";
import Card from "../../components/card/Card";
import { data } from "./Data";

function WeOffer() {
  return (
    <section id="offer" className="offer-section">
      <div className="offer-container">
        <div className="offer-header">
          <span className="offer-description">Nuestro compromiso</span>
          <h1 className="offer-title">
            Aumenta la presencia digital de tu PYME con nuestro servicio.
          </h1>
        </div>
        <div className="offer-card-container">
          {data.map((offer, index) => (
            <Card
              key={index}
              img={offer.img}
              title={offer.title}
              description={offer.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default WeOffer;