import React from 'react'
import './whyus.scss'
import { data } from './Data'
import CardWhy from '../../components/card-why/CardWhy'
import why from '../../assets/images/why.webp'

function WhyUs() {
  return (
    <section id='why-us' className="section-why">
      <div className="why-container">
        
        <div className="half-width-container">
          <div className="why-header">
            <span className="why-description">Por qué elegirnos</span>
            <h1 className="why-title">
              Más que un diseño web, una estrategia de éxito.
            </h1>
            <h2 className="why-subtitle">
              Cuando confías en nosotros, te garantizamos un servicio de calidad respaldado por más de 7 años de experiencia en el sector.
            </h2>
          </div>
          <div className='why-list-container'>
            {data.map((why, index) => (
                <CardWhy key={index} img={why.img} title={why.title} description={why.description} style={why.style} />
            ))}
          </div>
        </div>
        <div className="half-width-container">
          <img
            className="why-image-container"
            src={why}
            alt="serviceimage"
          ></img>
        </div>
      </div>
    </section>
  )
}

export default WhyUs
