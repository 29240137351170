import React from "react";
import "./card.scss";

function Card(props) {
  return (
    <div className="card">
      <div className="card-header">
        <img className="card-ico" src={props.img} alt={props.title} />
        <h1 className="card-title">{props.title}</h1>
      </div>
      <div className="card-container">
        <span className="card-description">{props.description}</span>
      </div>
    </div>
  );
}

export default Card;
