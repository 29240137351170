import React from 'react'
import './secondarybutton.scss'

function SecondaryButton(props) {
  return (
    <button className='secondary-button' onClick={props.onClick}>
      {props.title}
    </button>
  )
}

export default SecondaryButton
