import React from 'react'
import './cardwork.scss'

function CardWork(props) {
  return (
    <div className='card-work'>
      <div className='card-img-container'>
        <img className='card-img' src={props.img} alt={props.title}/>
      </div>
      <div className='card-work-container'>
        <h1 className='card-work-title'>{props.title}</h1>
        <p className='card-work-description'>{props.description}</p>
      </div>
    </div>
  )
}

export default CardWork
