import React from 'react'
import './work.scss'
import { data } from './Data'
import CardWork from '../../components/card-work/CardWork'
import work from '../../assets/images/wotk-avatar.webp';

function Work() {
  return (
    <section id='work' className="section-work">
      <div className="work-container">
        <div className="half-width-container">
          <img
            className="work-image-container"
            src={work}
            alt="workimage"
          ></img>
        </div>
        <div className="half-width-container">
          <div className="work-header">
            <span className="work-description">Cómo trabajamos</span>
            <h1 className="work-title">
              Desarrollamos un proceso adaptado a tu negocio.
            </h1>
          </div>
          <div className='work-list'>
            {data.map((item, index) => (
                <CardWork key={index} img={item.img} title={item.title} description={item.description} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Work