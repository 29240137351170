import React, { useState, useEffect, useRef, useMemo } from 'react';
import logo from '../../assets/images/logo2.png';
import './nav.scss';

function Nav() {
  const [scrolled, setScrolled] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState('home');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navRef = useRef(null);
  const scrollTimeoutRef = useRef(null);
  
  const titlesMap = useMemo(() => ({
    home: 'Inicio',
    services: 'Servicio',
    offer: 'Qué ofrecemos',
    work: 'Funcionamiento',
    'why-us': 'Por qué elegirnos',
    contact: 'Contacto'
  }), []);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);

      clearTimeout(scrollTimeoutRef.current);

      scrollTimeoutRef.current = setTimeout(() => {
        const sections = document.querySelectorAll('section');
        const navHeight = navRef.current.offsetHeight;

        sections.forEach((section) => {
          const top = section.offsetTop - navHeight;
          const bottom = top + section.offsetHeight;
          const scrollPosition = window.scrollY;

          if (scrollPosition >= top && scrollPosition < bottom) {
            setActiveNavItem(section.id);
            const title = titlesMap[section.id];
            document.title = `PYMEPAGE | ${title}`;
            window.history.pushState({}, '', `/${section.id}`);
          }
        });
      }, 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [titlesMap]);

  const handleNavLinkClick = (id, event) => {
    event.preventDefault();
    setActiveNavItem(id);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      const title = titlesMap[id];
      document.title = `PYMEPAGE | ${title}`;
      window.history.pushState({}, '', `/${id}`);
    }
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav ref={navRef} className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-content">
        <div className="logo">
          <img src={logo} alt='logo'/>
        </div>
        <div className={`menu-icon ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <ul className={`nav-list ${isMobileMenuOpen ? 'open' : ''}`}>
          <li className={`nav-item ${activeNavItem === 'home' ? 'active' : ''}`}><a href="/inicio" onClick={(e) => handleNavLinkClick("home", e)}>Inicio</a></li>
          <li className={`nav-item ${activeNavItem === 'services' ? 'active' : ''}`}><a href="/servicios" onClick={(e) => handleNavLinkClick("services", e)}>Servicio</a></li>
          <li className={`nav-item ${activeNavItem === 'offer' ? 'active' : ''}`}><a href="/que-ofrecemos" onClick={(e) => handleNavLinkClick("offer", e)}>Qué ofrecemos</a></li>
          <li className={`nav-item ${activeNavItem === 'work' ? 'active' : ''}`}><a href="/funcionamiento" onClick={(e) => handleNavLinkClick("work", e)}>Funcionamiento</a></li>
          <li className={`nav-item ${activeNavItem === 'why-us' ? 'active' : ''}`}><a href="/por-que-nosotros" onClick={(e) => handleNavLinkClick("why-us", e)}>Por qué elegirnos</a></li>
          <li className={`nav-item ${activeNavItem === 'contact' ? 'active' : ''}`}><a href="/contacto" onClick={(e) => handleNavLinkClick("contact", e)}>Contacto</a></li>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
