export const data = [
  {
    img: require("../../assets/images/phone-ico.webp"),
    title: "Atención Personalizada",
    description:
      "Trabajaremos contigo codo con codo y de la forma más cercana posible, informándote en todo momento, para que no te sientas solo en el camino.",
  },
  {
    img: require("../../assets/images/design-ico.webp"),
    title: "Historial comprobado de Éxito",
    description:
      "Nos enorgullece contar con la confianza y satisfacción de más de 300 clientes que han aumentado su visibilidad y ventas con nosotros.",
    style: {
      marginLeft: "10px",
      padding: "20px",
      backgroundColor: "white",
      borderRadius: "10px",
      boxShadow: "0px 0px 32px -9px rgba(0, 0, 0, 0.1)"
    },
  },
  {
    img: require("../../assets/images/goal-ico.webp"),
    title: "Expertos en Marketing Digital",
    description:
      "No todo es una página bonita y atractiva, hace falta una estrategia detrás de ella que esté orientada a los resultados que deseas conseguir.",
  },
];