import React from 'react';
import './hero.scss';
import PrimaryButton from '../../components/button/primarybutton/PrimaryButton';
import SecondaryButton from '../../components/button/secondarybutton/SecondaryButton';
import desk from '../../assets/images/home-desk.webp';

function Hero() {
  const handleClick = (id, event) => {
    event.preventDefault();
    const element = document.getElementById(id);
    console.log(element);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section id='home' className='section-hero'>
      <div className='hero-container'>
        <div className='half-width-container'>
          <div className='hero-header'>
            <span className='hero-description'>Impulsa tu negocio con presencia digital</span>
            <h1 className='hero-title'>Diseño web profesional para PYMES</h1>
            <h2 className='hero-subtitle'>Crea una presencia en línea atractiva y efectiva para tu empresa. Aumenta tus ventas y visibilidad con nuestro servicio de diseño web a medida.</h2>
          </div>
          <div className='hero-buttons'>
            <PrimaryButton title="Descubre más" onClick={(e) => handleClick('services', e)} />
            <SecondaryButton title="Contáctanos" onClick={(e) => handleClick('contact', e)} />
          </div>
        </div>
        <div className='half-width-container'>
          <img className='hero-image-container' src={desk} alt='heroimage'></img>
        </div>
      </div>
    </section>
  );
}

export default Hero;
