import React, { useState } from "react";
import "./contact.scss";
import PrimaryButton from "../../components/button/primarybutton/PrimaryButton";
import contact from "../../assets/images/contact-avatar.webp";
import ReactModal from "react-modal";
import emailjs from "@emailjs/browser";
import CircularProgress from '@mui/material/CircularProgress';

ReactModal.setAppElement("#root");

function Contact() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    nombre: "",
    telefono: "",
    email: "",
    nombreNegocio: "",
    redesSociales: "",
    mensaje: "",
  });
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!formValues.nombre || !formValues.email || !termsAccepted) {
      alert("Por favor, rellena el nombre y el correo electrónico, y acepta los términos y condiciones.");
      return;
    }

    setIsLoading(true);

    emailjs.send('service_hqyg4e6', 'template_cx1l5i9', formValues, '2C5GEQ90tS7vWjHtH')
      .then((result) => {
        console.log(result.text);
        setIsLoading(false);
        alert("Mensaje enviado correctamente");
        setFormValues({
          nombre: "",
          telefono: "",
          email: "",
          nombreNegocio: "",
          redesSociales: "",
          mensaje: "",
        });
        setTermsAccepted(false);
      }, (error) => {
        console.error(error.text);
        setIsLoading(false);
        alert("Ocurrió un error al enviar el mensaje. Inténtalo nuevamente.");
      });
  };

  return (
    <section id="contact" className="contact-section">
      <div className="contact-container">
        <div className="contact-header">
          <span className="contact-description">Contáctanos</span>
          <h1 className="contact-title">
            ¿Listo para llevar tu negocio al siguiente nivel?
          </h1>
        </div>
        <div className="contact-form-container">
          <div className="half-width-container">
            <img
              className="contact-image-container"
              src={contact}
              alt="serviceimage"
            ></img>
          </div>
          <div className="contact-form"></div>
          <div className="half-width-container">
            <div className="contact-form">
              <div className="contact-form-header">
                <h1 className="contact-form-title">Envíanos un mensaje</h1>
                <h2 className="contact-form-subtitle">
                  Todos los correos electrónicos se responden en un máximo de 24
                  horas laborables.{" "}
                  <b>Por favor, revisa tu bandeja de Spam o Promociones.</b>
                </h2>
              </div>
              <div className="contact-form-items">
                <form onSubmit={sendEmail}>
                  <div className="contact-form-row">
                    <div className="contact-form-input">
                      <label className="label-form" htmlFor="nombre">Nombre</label>
                      <input id="nombre" className="input-form" type="text" value={formValues.nombre} onChange={handleInputChange} required />
                    </div>
                    <div className="contact-form-input">
                      <label className="label-form" htmlFor="telefono">Teléfono</label>
                      <input id="telefono" className="input-form" type="tel" value={formValues.telefono} onChange={handleInputChange} />
                    </div>
                  </div>

                  <div className="contact-form-row">
                    <div className="contact-form-input">
                      <label className="label-form" htmlFor="email">Correo Electrónico</label>
                      <input id="email" className="input-form" type="email" value={formValues.email} onChange={handleInputChange} required />
                    </div>
                    <div className="contact-form-input">
                      <label className="label-form" htmlFor="nombreNegocio">Nombre del Negocio</label>
                      <input id="nombreNegocio" className="input-form" type="text" value={formValues.nombreNegocio} onChange={handleInputChange} />
                    </div>
                  </div>

                  <div className="contact-form-input">
                    <label className="label-form" htmlFor="redesSociales">Redes Sociales</label>
                    <input id="redesSociales" className="input-form" type="text" value={formValues.redesSociales} onChange={handleInputChange} />
                  </div>

                  <div className="contact-form-input">
                    <label className="label-form" htmlFor="mensaje">Mensaje</label>
                    <textarea id="mensaje" className="text-area" value={formValues.mensaje} onChange={handleInputChange} />
                  </div>

                  <div className="contact-form-privacy">
                    <input 
                      type="checkbox" 
                      id="privacy-checkbox" 
                      checked={termsAccepted}
                      onChange={handleTermsChange}
                      required 
                    />
                    <label htmlFor="privacy-checkbox">
                      Acepto la 
                    </label>
                    <p onClick={openModal}>Política de Privacidad</p>
                  </div>

                  <div className="submit-button-container">
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <PrimaryButton title="Enviar Mensaje" type="submit" disabled={!termsAccepted || !formValues.nombre || !formValues.email} />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className='modal'
        contentLabel="Política de Privacidad"
        overlayClassName='overlay'
      >
        <div className="modal-container">
          <h2>Política de Privacidad</h2>
          <div className="modal-body">
            <p>**Última actualización:** 22 de mayo de 2024</p>
            {/* Contenido de la política de privacidad */}
            <p>Tu privacidad es importante para nosotros. Esta política de privacidad explica qué datos personales recopilamos y cómo los usamos.</p>
            <h3>Información que Recopilamos</h3>
            <p>Recopilamos información personal que nos proporcionas directamente, como tu nombre, correo electrónico, número de teléfono, nombre del negocio y redes sociales.</p>
            <h3>Cómo Usamos Tu Información</h3>
            <p>Usamos la información que recopilamos para responder a tus consultas, proporcionar servicios y mejorar nuestra oferta de productos.</p>
            <h3>Compartir y Divulgar Información</h3>
            <p>No compartimos tu información personal con terceros, excepto cuando sea necesario para cumplir con la ley o con tu consentimiento explícito.</p>
            <h3>Tus Derechos</h3>
            <p>Tienes derecho a acceder, corregir o eliminar tu información personal. Para ejercer estos derechos, por favor contáctanos.</p>
            <h3>Seguridad</h3>
            <p>Implementamos medidas de seguridad para proteger tu información personal contra acceso no autorizado y divulgación.</p>
            <h3>Cambios a Esta Política</h3>
            <p>Podemos actualizar esta política de privacidad de vez en cuando. Te notificaremos cualquier cambio publicando la nueva política en nuestro sitio web.</p>
            <h3>Contáctanos</h3>
            <p>Si tienes alguna pregunta sobre esta política de privacidad, por favor contáctanos a través de nuestro formulario de contacto.</p>
          </div>
          <PrimaryButton title="Cerrar" onClick={closeModal}></PrimaryButton>
        </div>
      </ReactModal>
    </section>
  );
}

export default Contact;