import React from 'react'
import './cardwhy.scss'

function CardWhy(props) {
    return (
        <div className='card-why' style={props.style}>
          <div className='card-img-container'>
            <img className='card-img' src={props.img} alt={props.title}/>
          </div>
          <div className='card-why-container'>
            <h1 className='card-why-title'>{props.title}</h1>
            <p className='card-why-description'>{props.description}</p>
          </div>
        </div>
      )
}

export default CardWhy
