import React from "react";
import "./services.scss";
import PrimaryButton from "../../components/button/primarybutton/PrimaryButton";
import SecondaryButton from "../../components/button/secondarybutton/SecondaryButton";
import ico from '../../assets/images/tick-ico.webp';
import service from '../../assets/images/service.webp';

function Services() {
  const handleClick = (id, event) => {
    event.preventDefault();
    const element = document.getElementById(id);
    console.log(element);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section id="services" className="section-services">
      <div className="services-container">
        <div className="half-width-container">
          <img
            className="services-image-container"
            src={service}
            alt="serviceimage"
          ></img>
        </div>
        <div className="half-width-container">
          <div className="services-header">
            <span className="services-description">Nuestro servicio</span>
            <h1 className="services-title">
              Aumenta la presencia online de tu PYME y expande tu alcance.
            </h1>
            <h2 className="services-subtitle">
              En la era digital, tener una presencia en línea sólida es esencial para el crecimiento empresarial. En PymePage, te ayudamos a destacar con un diseño web profesional que impulsa tus ventas y te conecta con nuevos clientes.
            </h2>
          </div>
          <div className="services-container-list">
            <div className="services-list-group">
              <ul className="services-list">
                <li className="services-list-item">
                  <img className="services-list-icon" src={ico} alt="ico"/>
                  <span className="services-list-text">Mejora tu visibilidad en internet</span>
                </li>
                <li className="services-list-item">
                  <img className="services-list-icon" src={ico} alt="ico"/>
                  <span className="services-list-text">Ofrece un servicio al cliente excepcional</span>
                </li>
                <li className="services-list-item">
                  <img className="services-list-icon" src={ico} alt="ico"/>
                  <span className="services-list-text">Rompe las barreras geográficas</span>
                </li>
              </ul>
            </div>
            <div className="services-list-group">
              <ul className="services-list">
                <li className="services-list-item">
                  <img className="services-list-icon" src={ico} alt="ico"/>
                  <span className="services-list-text">Atrae a más clientes potenciales</span>
                </li>
                <li className="services-list-item">
                  <img className="services-list-icon" src={ico} alt="ico"/>
                  <span className="services-list-text">Automatiza tus procesos comerciales</span>
                </li>
                <li className="services-list-item">
                  <img className="services-list-icon" src={ico} alt="ico"/>
                  <span className="services-list-text">Obtén acceso a análisis y métricas útiles</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="services-buttons">
            <PrimaryButton title="Descubre lo que ofrecemos" onClick={(e) => handleClick('offer', e)}/>
            <SecondaryButton title="Cómo trabajamos" onClick={(e) => handleClick('work', e)}/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
