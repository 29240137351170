import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Nav from './components/nav/Nav';
import Contact from './pages/contact/Contact';
import Hero from './pages/hero/Hero';
import Services from './pages/services/Services';
import WeOffer from './pages/weoffer/WeOffer';
import WhyUs from './pages/whyus/WhyUs';
import Work from './pages/work/Work';
import './App.css';

function App() {
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  // Ejemplo de establecimiento de una cookie al cargar la página
  useEffect(() => {
    const cookieAceptacion = Cookies.get('cookieAceptar');

    if (!cookieAceptacion) {
      setShowCookieBanner(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    Cookies.set('cookieAceptacion', 'true', { expires: 30 });
    setShowCookieBanner(false);
  };

  const handleDeclineCookies = () => {
    setShowCookieBanner(false);
  };

  return (
    <>
      <Nav />
      <Hero />
      <Services />
      <WeOffer />
      <Work />
      <WhyUs />
      <Contact />
      {showCookieBanner && (
        <div className="cookie-banner">
          <p>Este sitio web utiliza cookies para mejorar tu experiencia. ¿Aceptas el uso de cookies?</p>
          <button onClick={handleAcceptCookies}>Aceptar</button>
          <button onClick={handleDeclineCookies}>Rechazar</button>
        </div>
      )}
    </>
  );
}

export default App;
